import './Footer.css';

function Footer() {
    return (
        <footer className="footer">
            <small>Desarrollado y diseñado por DiegoT4l. &copy;2023. Todos los derechos reservados.</small>
        </footer>
    );
}

export default Footer;